<template>
  <div class="flex-grow-1 container-p-y">
    <div class="row">
      <div class="col-lg-12 mb-4 order-0">
        <div class="card">
          <div class="d-flex align-items-end row">
            <div class="col-sm-12">
              <div class="card-body text-start">
                <h5 class="card-title text-primary fs-4">Welcome On Board! 🎉</h5>
                <p class="mb-4 fs-5">
                  We’re thrilled to have you with us at Box on Wheel!
                  Your account is now active, and our services will be fully available to you shortly. We’ll
                  notify you as soon as everything is set up and ready to go.
                </p>

              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: "Home",

  created() {

    if (localStorage.getItem("accessToken") == null) {
      this.$router.push({ name: "Login" });
    }

  },



};
</script>
