<template>

<div class="layout-wrapper layout-content-navbar">
    <div class="layout-container">
      <!-- Sidebar -->
      <Sidebar v-if="showSidebar" />
      
      <!-- Main Content -->
      <div class="layout-page">
        <!-- Header -->
        <Header v-if="showHeader" />
        <!-- Main View -->
        <div class="content-wrapper">
          <router-view />


          <!-- Footer -->
          <Footer v-if="showFooter" />
          <!-- / Footer -->

          <div class="content-backdrop fade"></div>
        </div>


      </div>

    </div>

    <!-- Overlay -->
    <div class="layout-overlay layout-menu-toggle"></div>
  </div>
</template>

<script>
import Sidebar from "@/components/common/Sidebar.vue";
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  components: {
    Sidebar,
    Header,
    Footer,
  },
  data() {
    return {
      // Control visibility of components
      showSidebar: true,
      showHeader: true,
      showFooter: true,
    };
  },
  watch: {
    // Watch for route changes to dynamically show/hide components
    $route(to) {
      this.configureLayout(to.meta);
    },
  },
  mounted() {
    this.configureLayout(this.$route.meta);
  },
  methods: {
    configureLayout(meta) {
      // Adjust layout based on route meta
      this.showSidebar = meta.sidebarVisible !== false;
      this.showHeader = meta.headerVisible !== false;
      this.showFooter = meta.footerVisible !== false;
    },
  },
};
</script>

<style>
#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.layout-page {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.content-wrapper {
  flex: 1;
  padding: 20px;
  background-color: #f8f9fa;
}

.menu-vertical .menu-item .menu-link,
.menu-vertical .menu-block {
  padding: 0.2rem 0.5rem !important;
}



@media (max-width: 991.98px) {
  #layout-menu {
    position: absolute;
    z-index: 1050;
    left: -300px;
    transition: left 0.3s ease-in-out;
  }
  #layout-menu.menu-open {
    left: 0;
  }
}

</style>
